<template>
    <main class="page promo-tools-page">
        <div class="page__body promo-tools__page-content">

            <div class="first-screen offset-section_small first-screen_full">
                <div class="promo-tools__page-container">

                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <BreadCrumbs :links="breadCrumbsLinks" />
                    <!-- ==================================== BREADCRUMBS ========================================= -->

                    <div class="first-screen__body">
                        <div class="first-screen__left">
                            <div class="page-heading">
                                <h1>
                                    <span>TOURNAMENTS</span>
                                </h1>
                            </div>
                        </div>
                        <div class="first-screen__right"></div>
                    </div>
                </div>
            </div>

            <!-- ==================================== PAGE TITLE AND DESCRIPTION ========================================= -->
            <div class="promo-tools__page-container">
                <div class="promo-tools__page_description">
                  <div class="ptp-image">
                    <img src="@/assets/img/promo-tools/icons/tournaments.png" alt="3oaks" class="promo-tools__page-image">
                  </div>
                  <div class="ptp-description">
                    <div class="promo-tools__text-container">
                        <p>
                            3 Oaks Tournaments create exciting competitions within games, where players compete
                            for top spots on the Leaderboard and the thrilling rewards they carry. By tapping
                            into players' natural <strong>competitive drive</strong>, the tool keeps them motivated and eager to play
                            more, so <strong>engagement and retention levels catapult</strong> as a result.
                        </p>
                        <p>
                            With easy setup and quick launch, Tournaments bring loads of excitement to players'
                            screens with engaging features like a countdown timer, welcome pop-ups, a minified
                            in-game widget and a real-time leaderboard. Additionally, the Hot Period feature can
                            be activated to spark a thrilling surge in betting activity during the tournament campaign.
                        </p>
                    </div>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE TITLE AND DESCRIPTION ========================================= -->

            <!-- ==================================== PAGE NAVIGATION ========================================= -->
            <div class="promo-tools__page-container">
                <h2 class="promo-tools__page-sub-title">
                  <strong>JUMP TO</strong>
                </h2>
                <PageNav :items="pageNavList" />
            </div>
            <!-- ==================================== PAGE NAVIGATION ========================================= -->

            <!-- ==================================== PAGE CONTENT: WHY WORTH IT ========================================= -->
            <div class="promo-tools__page-container" id="why-it-worth">
                <h2 class="promo-tools__page-sub-title">
                  <strong>WHY IT WORTH</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                    <ul class="promo-tools__oaks-decorated-list">
                        <li>
                          <strong>No need</strong> for <strong>additional integrations</strong> or <strong>API updates</strong> - we have an out-of-the-box solution!
                        </li>
                        <li>
                          Easy, flexible and customisable tournament setup and operation process, which is ready to <strong>Go Live</strong> in as little as <strong>15-20 minutes</strong>
                        </li>
                        <li>
                          We provide <strong>promotional materials</strong> for each Network Campaign
                        </li>
                        <li>
                          <strong>Tournament results</strong> for each campaign are downloadable in our Back Office Promo Campaigns section
                        </li>
                        <li>
                          A fantastic gamification tool that appeals to all sorts of bettors, from casual players to hardcore VIPs, <strong>aimed at boosting their Engagement, Retention, and Acquisition</strong>
                        </li>
                        <li>
                          Engage your players with minimum investment!
                        </li>
                    </ul>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: WHY WORTH IT ========================================= -->

            <!-- ==================================== PAGE CONTENT: WORKFLOW LIST ========================================= -->
            <div class="promo-tools__page-container" id="workflow">
                <h2 class="promo-tools__page-sub-title">
                  <strong>WORKFLOW</strong>
                </h2>
              <ol class="promo-tools__workflow-list">
                <PromoWorkflowLi>
                  We finalise all Tournament conditions and details
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  3 Oaks handles Tournament setup and provides tech support
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  You advertise the Tournament & games to your audience
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  Tournament pre-notification
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Tournament runs in real-time and the leaderboard is accessible in-game
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Tournament has finished; the leaderboard is compiled and winners are notified; post-notification
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Tournament Results Report is available for download
                </PromoWorkflowLi>
              </ol>
                <div class="promo-tools__stages">
                  <div class="pts-explanation">
                    <div class="promo-tools__gradient-decorated-block">
                      <PromoExpanded defaultExpanded>
                        <template #trigger>Promotion</template>
                        <template #content>
                          <p class="promo-tools__text ptp-gray ptp-with-offset">
                            To warm up players and keep them updated about the upcoming Tournament:
                          </p>
                          <ul class="promo-tools__oaks-decorated-list">
                            <li>
                              Players get notifications about the upcoming Tournament
                            </li>
                            <li>
                              The <strong>Countdown Timer</strong> builds anticipation for the thrilling competition
                            </li>
                            <li>
                              Push Notifications are applied to selected games participating in the Tournament
                            </li>
                          </ul>
                        </template>
                      </PromoExpanded>
                    </div>
                    <div class="promo-tools__gradient-decorated-block">
                      <PromoExpanded>
                        <template #trigger>The Tournament is running</template>
                        <template #content>
                          <p class="promo-tools__text ptp-gray ptp-with-offset">
                            When entering the game, players are guided with the following:
                          </p>
                          <div class="promo-tools__text-container">
                            <div>
                              <h4 class="promo-tools__small-title ptp-small ptp-with-offset ptp-with-margin-bottom ptp-orange">
                                POP-UPs
                              </h4>
                              <ul class="promo-tools__oaks-decorated-list">
                                <li>
                                  Welcome Information
                                </li>
                              </ul>
                            </div>
                            <div>
                              <h4 class="promo-tools__small-title ptp-small ptp-with-offset ptp-with-margin-bottom ptp-orange">
                                <span class="ptp-thin">Dynamic</span> TOP BARs</h4>
                              <ul class="promo-tools__oaks-decorated-list">
                                <li>
                                  For the Non-Qualified player: score and the number of spins left to qualify
                                </li>
                                <li>
                                  For the Qualified player: place, score and prize
                                </li>
                              </ul>
                            </div>
                            <div>
                              <h4 class="promo-tools__small-title ptp-small ptp-with-offset ptp-with-margin-bottom ptp-orange">
                                MAIN MENU <span class="ptp-thin">featuring</span>
                              </h4>
                              <ul class="promo-tools__oaks-decorated-list">
                                <li>
                                  Leaderboard
                                </li>
                                <li>
                                  Prize Pool
                                </li>
                                <li>
                                  Rules
                                </li>
                              </ul>
                            </div>
                            <div>
                              <h4 class="promo-tools__small-title ptp-small ptp-with-offset ptp-with-margin-bottom ptp-orange">
                                REAL-TIME LEADERBOARD</h4>
                              <ul class="promo-tools__oaks-decorated-list">
                                <li>
                                  Displays the player’s position and unique ID
                                </li>
                                <li>
                                  The leaderboard line for the Non-Qualified Player is striped in Grey and sorted to the approximate position on the leaderboard
                                </li>
                                <li>
                                  Offers a replay of the player’s best round (in certain disciplines)
                                </li>
                              </ul>
                            </div>
                            <div>
                              <h4 class="promo-tools__small-title ptp-small ptp-with-offset ptp-with-margin-bottom ptp-orange">
                                MINIFIED ‘REAL-TIME TRACKER’ WIDGET</h4>
                              <ul class="promo-tools__oaks-decorated-list">
                                <li>
                                  The built-in minified widget is always present on the screen
                                </li>
                                <li>
                                  It displays the player's
                                  <strong>real-time Tournament status</strong> as well as the
                                  <strong>Countdown Timer</strong>.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </template>
                      </PromoExpanded>
                    </div>
                  </div>
                  <div class="pts-images">
                    <PromoSlider>
                      <template #slide-0>
                        <div class="promo-tools__image">
                          <img src="@/assets/img/promo-tools/tournaments/tournament-promotion.png">
                          <h4 class="promo-tools__small-title">Promotion</h4>
                        </div>
                      </template>
                      <template #slide-1>
                        <div class="promo-tools__image">
                          <img src="@/assets/img/promo-tools/tournaments/tournament-is-running.png">
                          <h4 class="promo-tools__small-title">The Tournament is running</h4>
                        </div>
                      </template>
                      <template #slide-2>
                        <div class="promo-tools__image">
                          <img src="@/assets/img/promo-tools/tournaments/tournament-is-over.png">
                          <h4 class="promo-tools__small-title">Tournament is over</h4>
                        </div>
                      </template>
                      <template #slide-3>
                        <div class="promo-tools__image">
                          <img src="@/assets/img/promo-tools/tournaments/tournament-place-notification.png">
                          <h4 class="promo-tools__small-title">Place notification</h4>
                        </div>
                      </template>
                    </PromoSlider>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: WORKFLOW LIST ========================================= -->

            <!-- ==================================== PAGE CONTENT: SCORING RULES ========================================= -->
            <div class="promo-tools__page-container" id="scoring-rules">
                <h2 class="promo-tools__page-sub-title">
                  <strong>SCORING RULES</strong>
                </h2>
                <div class="promo-tools__grid-2">
                    <div class="promo-tools__gradient-decorated-block">
                      <div class="promo-tools__text-container">
                        <div>
                          <h4 class="promo-tools__small-title">
                            Cumulative scoring
                          </h4>
                          <p class="promo-tools__text ptp-gray ptp-small">
                            For long-term Tournaments (points accumulate throughout the entire Tournament):
                          </p>
                        </div>
                        <div>
                          <p class="promo-tools__text ptp-orange ptp-capitalized">
                            <strong>WR</strong>
                          </p>
                          <p class="promo-tools__text ptp-gray ptp-capitalized">WIN RACE</p>
                          <p>The player earns 1 point for each EUR 1 in a winning spin</p>
                        </div>
                        <div>
                          <p class="promo-tools__text ptp-orange ptp-capitalized">
                            <strong>BR</strong>
                          </p>
                          <p class="promo-tools__text ptp-gray ptp-capitalized">BET RACE</p>
                          <p>The player earns 1 point for each EUR 1 in bets</p>
                        </div>
                        <div>
                          <p class="promo-tools__text ptp-orange ptp-capitalized">
                            <strong>MR</strong>
                          </p>
                          <p class="promo-tools__text ptp-gray ptp-capitalized">MULTIPLIER RACE</p>
                          <p>The player earns 1 point for each x1 multiplier in a winning spin</p>
                        </div>
                      </div>
                    </div>

                    <div class="promo-tools__gradient-decorated-block">
                      <div class="promo-tools__text-container">
                        <div>
                          <h4 class="promo-tools__small-title">Non-cumulative</h4>
                          <p class="promo-tools__text ptp-gray ptp-small">
                            scoring for short-term Tournaments
                          </p>
                        </div>
                        <p>The highest single round multiplier</p>
                        <p>The highest single round payout</p>
                      </div>
                    </div>
                </div>
                <div class="promo-tools__bottom-line">
                  <p>Any of the above-mentioned scoring rules can be mixed into combinations to create even more dynamic competitions!</p>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: SCORING RULES ========================================= -->

            <!-- ==================================== PAGE CONTENT: PRIZE AUTO-PAYOUT FEATURE ========================================= -->
            <div class="promo-tools__page-container" id="prize-auto-payout-feature">
                <h2 class="promo-tools__page-sub-title">
                  <strong>Prize auto-payout feature</strong>
                </h2>
                <div>
                  <div class="promo-tools__gradient-decorated-block">
                    <p>
                      Prizes are paid automatically to winners if the <strong>auto-payout feature</strong>
                      is enabled. If the <strong>auto-payout feature is disabled</strong>, players get a
                      Win Pop-Up stating that the prize will be manually credited to their accounts by the
                      operator in <strong>72 hours</strong>.
                    </p>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: PRIZE AUTO-PAYOUT FEATURE ========================================= -->

            <!-- ==================================== PAGE CONTENT: PRIZE OPTIONS ========================================= -->
            <div class="promo-tools__page-container" id="prize-options">
                <h2 class="promo-tools__page-sub-title">
                  <strong>PRIZE OPTIONS</strong>
                </h2>
                <div class="promo-tools__grid-splitted">
                  <div class="container-left">
                    <div class="promo-tools__gradient-decorated-block">
                      <div class="promo-tools__text-container">
                        <h4 class="promo-tools__small-title">MONEY</h4>
                        <div>
                          <p class="promo-tools__text ptp-orange capitalize ptp-bold">Money</p>
                        </div>
                        <div>
                          <p class="promo-tools__text ptp-orange capitalize ptp-bold">Progressive prize pool</p>
                          <p>
                            Starts from a guaranteed amount and then grows with participants’ bet percentage contribution
                          </p>
                        </div>
                        <div>
                          <p class="promo-tools__text ptp-orange capitalize ptp-bold">Fixed prize pool</p>
                          <p>
                            A predetermined fixed prize pool amount shared among the winners
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container-right-top">
                    <div class="promo-tools__gradient-decorated-block">
                      <div class="promo-tools__text-container">
                        <h4 class="promo-tools__small-title">FreeBets</h4>
                        <p>
                          Fixed free bets, flexible free bets and feature triggers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="container-right-bottom">
                    <div class="promo-tools__gradient-decorated-block">
                      <div class="promo-tools__text-container">
                        <h4 class="promo-tools__small-title">Custom prizes</h4>
                        <p class="">
                          (Travel packages, cutting-edge gadgets, luxury cars, etc.)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="promo-tools__bottom-line">
                  <p>
                      Prizes are paid automatically to winners if the <strong>auto-payout feature</strong> is enabled.
                  </p>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: PRIZE OPTIONS ========================================= -->

            <!-- ==================================== PAGE CONTENT: TOURNAMENT SETTINGS ========================================= -->
            <div class="promo-tools__page-container" id="tournament-settings">
                <h2 class="promo-tools__page-sub-title">
                  <strong>TOURNAMENT SETTINGS</strong>
                </h2>
                <div class="promo-tools__grid-2">
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Local campaigns</h4>
                      <p class="promo-tools__text ptp-gray ptp-with-offset">
                        Based on your preferences, you can fine-tune the following:
                      </p>
                      <ul class="promo-tools__oaks-decorated-list">
                        <li>
                          Prize pool (prize distribution by winning positions)
                        </li>
                        <li>
                          Campaign duration
                        </li>
                        <li>
                          Qualification: min bet amount, min number of spins
                        </li>
                        <li>
                          Participating games
                        </li>
                        <li>
                          Scoring rules
                        </li>
                        <li>
                          White/black-listing of brands participating in the Tournament (may be done via Token, prefix in playerID, etc.)
                        </li>
                      </ul>
                      <p class="promo-tools__text ptp-gray">
                        If you need assistance in choosing the scoring rules or the logic of prize money distribution, we can help you!
                      </p>
                    </div>
                  </div>
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Network campaigns:</h4>
                      <p class="promo-tools__text ptp-gray">
                        To participate in the network campaign, simply notify your Account Manager. We provide all the necessary promo materials.
                      </p>
                    </div>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: TOURNAMENT SETTINGS ========================================= -->
        </div>
    </main>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import PageNav from "./components/page_nav.vue";
import PromoSlider from "./components/promo_slider.vue";
import PromoExpanded from './components/promo_expanded.vue';
import PromoWorkflowLi from "./components/promo_workflow_li.vue";


export default {
    name: "PromoToolsTournaments",
    components: {
        BreadCrumbs,
        PageNav,
        PromoSlider,
        PromoExpanded,
        PromoWorkflowLi
    },
    props: {
        user: Object,
    },

    setup() {},

    mounted() {},

    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'Promo Tools',
                    href: this.$router.resolve({ name: 'client-area-promo-tools' }).path
                },

                {
                    title: 'Tournaments',
                }
            ],
            pageNavList: [
                {
                  displayText: 'Why it worth',
                  anchor: 'why-it-worth'
                },
                {
                  displayText: 'Workflow',
                  anchor: 'workflow'
                },
                {
                  displayText: 'Scoring rules',
                  anchor: 'scoring-rules'
                },
                {
                  displayText: 'Prize auto-payout feature',
                  anchor: 'prize-auto-payout-feature'
                },
                {
                  displayText: 'Prize option',
                  anchor: 'prize-options'
                },
                {
                  displayText: 'Tournament settings',
                  anchor: 'tournament-settings'
                },
            ],
        }
    },

    methods: {},
}

</script>