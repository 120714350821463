<template>
    <main class="page promo-tools-page">
        <div class="page__body promo-tools__page-content">

            <div class="first-screen offset-section_small first-screen_full">
                <div class="promo-tools__page-container">

                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <BreadCrumbs :links="breadCrumbsLinks" />
                    <!-- ==================================== BREADCRUMBS ========================================= -->

                    <div class="first-screen__body">
                        <div class="first-screen__left">
                            <div class="page-heading">
                                <h1>
                                    <span>FLIP TO WIN</span>
                                </h1>
                            </div>
                        </div>
                        <div class="first-screen__right"></div>
                    </div>
                </div>
            </div>

            <!-- ==================================== PAGE TITLE AND DESCRIPTION ========================================= -->
            <div class="promo-tools__page-container">
                <div class="promo-tools__page_description">
                  <div class="ptp-image">
                    <img src="@/assets/img/promo-tools/icons/flip-to-win.png" alt="3oaks" class="promo-tools__page-image">
                  </div>
                  <div class="ptp-description">
                    <div class="promo-tools__text-container">
                        <p>
                          The player fills the Progress Bar with <strong>same-bet</strong> spins to
                          activate the feature. Once activated, they make a FLIP to reveal the
                          <strong>guaranteed multiplier</strong>.
                        </p>
                        <p>
                          Each FLIP is activated by filling the Progress Bar, and the entire experience
                          unfolds in cycles of <strong>3 FLIPS</strong>:
                        </p>
                        <ul class="promo-tools__oaks-decorated-list">
                          <li>
                            <strong>FLIP 1</strong>: reveals a random multiplier out of the
                            <strong>five</strong> available multiplier tiers.
                          </li>
                          <li>
                            <strong>FLIP 2</strong>: reveals a random multiplier out of the
                            <strong>four</strong> multiplier tiers.
                          </li>
                          <li>
                            <strong>FLIP 3</strong>: reveals a random multiplier out of the
                            <strong>three</strong> multiplier tiers.
                          </li>
                        </ul>
                        <p>
                          With each FLIP, the lowest multiplier tier drops off, and the higher tiers remain
                          available as increasingly attractive prizes. This motivates players to place more
                          bets to advance up the prize ladder with each FLIP.
                        </p>
                      </div>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE TITLE AND DESCRIPTION ========================================= -->

            <!-- ==================================== PAGE NAVIGATION ========================================= -->
            <div class="promo-tools__page-container">
                <h2 class="promo-tools__page-sub-title">
                  <strong>JUMP TO</strong>
                </h2>
                <PageNav :items="pageNavList" />
            </div>
            <!-- ==================================== PAGE NAVIGATION ========================================= -->

            <!-- ==================================== PAGE CONTENT: WHY WORTH IT ========================================= -->
            <div class="promo-tools__page-container" id="why-it-worth">
                <h2 class="promo-tools__page-sub-title">
                  <strong>WHY IT WORTH</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                  <ul class="promo-tools__oaks-decorated-list">
                    <li>
                      <strong>No need</strong> for <strong>additional setups</strong> or <strong>integrations</strong>
                    </li>
                    <li>
                      <strong>Auto-payouts</strong> with instant rewards
                    </li>
                    <li>
                      <strong>Reduces activity gaps</strong> between campaigns
                    </li>
                    <li>
                      Motivates players to <strong>place more bets</strong> as they aim at the final flip, where higher prize multipliers become available
                    </li>
                    <li>
                      <strong>Grows</strong> the bet amount per gaming session <strong>+ boosts</strong> the average spin number
                    </li>
                    <li>
                      <strong>Enhanced Engagement</strong>: personalised prizes tailored to individual player sessions and ranges
                    </li>
                    <li>
                      Guarantees a positive experience for players and fosters <strong>long-term loyalty</strong>
                    </li>
                    <li>
                      Available across the entire 3 Oaks portfolio
                    </li>
                  </ul>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: WHY WORTH IT ========================================= -->

            <!-- ==================================== PAGE CONTENT: HOW DOES IT WORKS ========================================= -->
            <div class="promo-tools__page-container" id="how-does-it-works">
                <h2 class="promo-tools__page-sub-title">
                  <strong>How does it work?</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                  <div class="promo-tools__text-container">
                    <p>
                      The player fills the Progress Bar with <strong>same-bet</strong>
                      spins to activate the feature. Once activated, they make a FLIP to reveal a
                      <strong>guaranteed multiplier</strong>.
                    </p>
                    <p>
                      Each <strong>FLIP</strong> is activated by filling the Progress Bar, and the
                      entire experience unfolds in cycles. Each cycle comprises <strong>3 FLIPS</strong>:
                    </p>
                    <ul class="promo-tools__oaks-decorated-list">
                      <li>
                        <strong>FLIP 1</strong>: the player reveals a random multiplier out of
                        <strong>5</strong> available bet multiplier tiers.
                      </li>
                      <li>
                        <strong>FLIP 2</strong>: the player reveals a random multiplier out of
                        <strong>4</strong> available multiplier tiers.
                      </li>
                      <li>
                        <strong>FLIP 3</strong>: the player reveals a random multiplier out of
                        <strong>3</strong> available multiplier tiers.
                      </li>
                    </ul>
                    <p>
                      With each FLIP, the lowest multiplier tier drops off and the higher ones remain
                      available as increasingly attractive prizes.
                    </p>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: HOW DOES IT WORKS ========================================= -->

            <!-- ==================================== PAGE CONTENT: WORKFLOW LIST ========================================= -->
            <div class="promo-tools__page-container" id="workflow">
                <h2 class="promo-tools__page-sub-title">
                  <strong>WORKFLOW</strong>
                </h2>
              <ol class="promo-tools__workflow-list">
                <PromoWorkflowLi>
                  We finalise all campaign conditions and details
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  3 Oaks handles Flip to Win setup and provides tech support
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  You advertise the Flip to Win campaign & games to your audience
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  Campaign pre-notification
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Flip to Win campaign runs in real-time
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The campaign has finished; post-notification
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Flip to Win Results Report is available for download
                </PromoWorkflowLi>
              </ol>
            </div>
            <!-- ==================================== PAGE CONTENT: WORKFLOW LIST ========================================= -->

            <!-- ==================================== PAGE CONTENT: SCORING RULES ========================================= -->
            <div class="promo-tools__page-container" id="scoring-rules">
                <h2 class="promo-tools__page-sub-title">
                  <strong>SCORING RULES</strong>
                </h2>
                <div>
                  <div class="promo-tools__gradient-decorated-block">
                    <p>
                      Every Flip guarantees a prize. To reach the Flip, the player must make a certain
                      number of spins with the same bet.
                    </p>
                    <p>
                      There’s no minimum qualifying bet required to activate the feature.
                    </p>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: SCORING RULES ========================================= -->

            <!-- ==================================== PAGE CONTENT: PRIZE AUTO-PAYOUT FEATURE ========================================= -->
            <div class="promo-tools__page-container" id="prize-auto-payout-feature">
                <h2 class="promo-tools__page-sub-title">
                  <strong>PRIZE AUTO-PAYOUT FEATURE</strong>
                </h2>
                <div>
                  <div class="promo-tools__gradient-decorated-block">
                    <p>
                      The auto-payout feature ensures that instant rewards are automatically
                      paid out within the Flip to Win campaign.
                    </p>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: PRIZE AUTO-PAYOUT FEATURE ========================================= -->

            <!-- ==================================== PAGE CONTENT: PRIZE OPTIONS ========================================= -->
            <div class="promo-tools__page-container" id="prize-options">
                <h2 class="promo-tools__page-sub-title">
                  <strong>PRIZE OPTIONS</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                    <p>
                      The Flip to Win campaign offers prize multipliers as the only rewards available.
                    </p>
                  </div>
            </div>
            <!-- ==================================== PAGE CONTENT: PRIZE OPTIONS ========================================= -->

            <!-- ==================================== PAGE CONTENT: TOURNAMENT SETTINGS ========================================= -->
            <div class="promo-tools__page-container" id="flip-to-win-settings">
                <h2 class="promo-tools__page-sub-title">
                  <strong>FLIP TO WIN SETTINGS</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                  <div class="promo-tools__text-container">
                    <h4 class="promo-tools__small-title">Local campaigns</h4>
                    <p class="promo-tools__text ptp-gray ptp-with-offset">
                      With a single request from you, we can effortlessly set up multiple promotions.
                      Simply select your preferred campaign setup, and let 3 Oaks take care of the
                      rest to elevate your promo campaign.
                    </p>
                    <p class="promo-tools__text ptp-with-offset">
                      The following parameters can be customised to align with your preferences:
                    </p>
                    <ul class="promo-tools__oaks-decorated-list">
                      <li>
                        List of games
                      </li>
                      <li>
                        Campaign duration (ranging from 30 minutes to unlimited periods)
                      </li>
                      <li>
                        Number of spins remaining until the Flip
                      </li>
                      <li>
                        Size of prize multipliers
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: TOURNAMENT SETTINGS ========================================= -->

        </div>
    </main>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import PageNav from "./components/page_nav.vue";
import PromoWorkflowLi from "./components/promo_workflow_li.vue";


export default {
    name: "PromoToolsFlipToWin",
    components: {
        BreadCrumbs,
        PageNav,
        PromoWorkflowLi
    },
    props: {
        user: Object,
    },
    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'Promo Tools',
                    href: this.$router.resolve({ name: 'client-area-promo-tools' }).path
                },

                {
                    title: 'Flip to win',
                }
            ],
            pageNavList: [
                {
                  displayText: 'Why it worth',
                  anchor: 'why-it-worth'
                },
                {
                  displayText: 'How does it works',
                  anchor: 'how-does-it-works'
                },
                {
                  displayText: 'Workflow',
                  anchor: 'workflow'
                },
                {
                  displayText: 'Scoring rules',
                  anchor: 'scoring-rules'
                },
                {
                  displayText: 'Prize auto-payout feature',
                  anchor: 'prize-auto-payout-feature'
                },
                {
                  displayText: 'Prize option',
                  anchor: 'prize-options'
                },
                {
                  displayText: 'Flip To Win settings',
                  anchor: 'flip-to-win-settings'
                },
            ],
        }
    },
}

</script>