<template>
    <main class="page promo-tools-jackpots">
        <div class="page__body promo-tools-jackpots__body">

            <div class="first-screen offset-section_small first-screen_full">
                <div class="_container">

                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <BreadCrumbs :links="breadCrumbsLinks" />
                    <!-- ==================================== BREADCRUMBS ========================================= -->

                    <div class="first-screen__body">
                        <div class="first-screen__left">
                            <div class="page-heading">
                                <h1>
                                    <span>JACKPOTS</span>
                                </h1>
                            </div>
                        </div>
                        <div class="first-screen__right"></div>
                    </div>
                </div>
            </div>

            <div class="_container">

                <div class="section-container section-container_first offset-section description-section">
                    <div class="right-side">
                        <div class="promo-card__image">
                            <img src="@/assets/img/promo-tools/icon-jackpots.png" alt="3oaks">
                        </div>
                    </div>
                    <div class="left-side">
                        <div class="_text-box">
                            <p>
                                Our Jackpots promo tool drives outstanding results for casinos by effectively solving the three main challenges operators typically encounter:
                                <ul class="oaks-list">
                                    <li>
                                        <p>Low player engagement rates</p>
                                    </li>
                                    <li>
                                        <p>Unpredictable spend</p>
                                    </li>
                                    <li>
                                        <p>Negative influence on RTP and game maths</p>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                <section class="offset-section">
                    <h6 class="offset-title full-width">Content</h6>
                    <div class="section-container section-container_second">
                        <div class="left-side">
                            <ul class="oaks-list">
                                <li>
                                    <a href="#why-it-worth" @click.prevent="onAnchorClick">WHY IT WORTH</a>
                                </li>
                                <li>
                                    <a href="#impact-on-game-maths" @click.prevent="onAnchorClick">IMPACT ON GAME MATHS</a>
                                </li>
                                <li>
                                    <a href="#workflow" @click.prevent="onAnchorClick">WORKFLOW</a>
                                </li>
                                <li>
                                    <a href="#scoring-rules" @click.prevent="onAnchorClick">SCORING RULES</a>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side">
                            <ul class="oaks-list">
                                <li>
                                    <a href="#prize-auto-payout-feature" @click.prevent="onAnchorClick">PRIZE AUTO-PAYOUT FEATURE</a>
                                </li>
                                <li>
                                    <a href="#jackpot-types" @click.prevent="onAnchorClick">JACKPOT TYPES</a>
                                </li>
                                <li>
                                    <a href="#flexible-configurations" @click.prevent="onAnchorClick">FLEXIBLE CONFIGURATIONS</a>
                                </li>
                                <li>
                                    <a href="#jackpots-settings" @click.prevent="onAnchorClick">JACKPOTS SETTINGS</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section class="offset-section" ref="why-it-worth-section">
                    <h6 class="offset-title full-width">WHY IT WORTH</h6>
                    <div class="section-container section-container_second">
                        <div class="left-side">
                            <ul class="oaks-list">
                                <li>
                                    <p>User-friendly: no additional integrations or setups, no need for a tech team, offers precise analytics</p>
                                </li>
                                <li>
                                    <p>Cost-efficient: no additional contracts, no setup fee, GGR compensation, predictable and limited Jackpot amounts</p>
                                </li>
                                <li>
                                    <p>The impact on game maths is minimised</p>
                                </li>
                                <li>
                                    <p>Compatible with other promotional tools</p>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side">
                            <ul class="oaks-list">
                                <li>
                                    <p>As the Jackpot countdown progresses, the excitement grows along with the number of bets</p>
                                </li>
                                <li>
                                    <p>Boosts retention and engagement rates</p>
                                </li>
                                <li>
                                    <p>Customisable widgets with appealing designs attract more players to Jackpot-enabled slots</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section class="offset-section" ref="impact-on-game-maths-section">
                    <h6 class="offset-title full-width">IMPACT ON GAME MATHS</h6>
                    <p>Our Jackpot Math Certificate allocates the percentage subtracted from the RTP to the Jackpot budget, enhancing the gaming experience by offering players a wider diversity of entertainment. This approach benefits both operators and their players.</p>
                </section>
            </div>

            <!-- ======================================= GAME FEATURES ======================================= -->
            <section class="news-geometry offset-section workflow-section" ref="workflow-section">
                <div class="_container">
                    <h6 class="offset-title full-width">Workflow</h6>

                    <div class="page-slider page-slider_user">
                        <div class="page-slider__view">
                            <div id="page-slider_user-1" class="page-slider__slider _swiper" ref="swiperFeatures">
                                <div class="swiper-wrapper">
                                    <div class="page-slider__slide swiper-slide">
                                        <div class="step-slide">
                                            <div class="step-slide__content">
                                                <div class="step-slide__slide">
                                                    <div>
                                                        <span>01</span>
                                                    </div>
                                                    <p>
                                                        <b>Pre-Setup</b>: we agree on the configuration settings and provide you with a demo of how it will work.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="step-slide__arrow"></div>
                                        </div>
                                    </div>
                                    <div class="page-slider__slide swiper-slide">
                                        <div class="step-slide">
                                            <div class="step-slide__content">
                                                <div class="step-slide__slide">
                                                    <div>
                                                        <span>02</span>
                                                    </div>
                                                    <p>
                                                        <b>Simulation</b>: we simulate the results of the chosen configurations and share them with you. If the results don’t match your expectations, we rerun the simulations until we achieve the perfect result.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="step-slide__arrow"></div>
                                        </div>
                                    </div>
                                    <div class="page-slider__slide swiper-slide">
                                        <div class="step-slide">
                                            <div class="step-slide__content">
                                                <div class="step-slide__slide">
                                                    <div>
                                                        <span>03</span>
                                                    </div>
                                                    <p>
                                                        <b>Approval</b>: we receive your approval
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="step-slide__arrow"></div>
                                        </div>
                                    </div>
                                    <div class="page-slider__slide swiper-slide">
                                        <div class="step-slide">
                                            <div class="step-slide__content">
                                                <div class="step-slide__slide">
                                                    <div>
                                                        <span>04</span>
                                                    </div>
                                                    <p>
                                                        <b>Setup</b>: the Jackpot goes live.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page-slider__controls">
                            <div class="page-slider__nav">
                                <div class="page-slider__progressbar">
                                    <div class="page-slider_user-1__progressbar" ref=swiperFeaturesProgressbar></div>
                                </div>
                                <div class="page-slider__buttons">
                                    <div class="page-slider__prev-button page-slider_user-1__prev _icon-arrow-1"
                                        ref="swiperFeaturesPrevSlideButton"></div>
                                    <div class="page-slider__next-button page-slider_user-1__next _icon-arrow-1"
                                        ref="swiperFeaturesNextSlideButton"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ======================================= GAME FEATURES ======================================= -->

            <div class="_container">
                <!-- ========================================================================== -->
                <section class="offset-section" ref="scoring-rules-section">
                    <h6 class="offset-title full-width">SCORING RULES</h6>
                    <p>Jackpot prizes are awarded randomly.</p>
                </section>
                <!-- ========================================================================== -->

            </div>

            <div class="_container">
                <!-- ========================================================================== -->
                <section class="offset-section" ref="prize-auto-payout-feature-section">
                    <h6 class="offset-title full-width">Prize auto-payout feature</h6>
                    <p>The auto-payout feature ensures that instant rewards are automatically paid out within the Jackpot campaign.</p>
                </section>
                <!-- ========================================================================== -->

            </div>

            <div class="_container">
                <section class="offset-section" ref="jackpot-types-section">
                    <h6 class="offset-title full-width">JACKPOT TYPES</h6>
                    <div class="section-container section-container_plain">
                        <div class="tournament-box tournament-box_small">
                            <div class="tournament-box__item">
                                <h4>CLASSIC JACKPOT</h4>
                                <p>An evergreen classic. Available in mini, minor, major and grand prize sizes</p>
                            </div>
                        </div>

                        <div class="tournament-box tournament-box_small">
                            <div class="tournament-box__item">
                                <h4>TIME JACKPOT</h4>
                                <p>You decide at which point in time the Jackpot pays out</p>
                            </div>
                        </div>

                        <div class="tournament-box tournament-box_small">
                            <div class="tournament-box__item">
                                <h4>MUST DROP JACKPOT</h4>
                                <p>You decide the amount at which the Jackpot drops</p>
                            </div>
                        </div>


                    </div>
                </section>

                <section class="offset-section" ref="flexible-configurations-section">
                    <h6 class="offset-title full-width">FLEXIBLE CONFIGURATIONS</h6>
                    <p>You have full control over the Jackpot configurations:</p>
                    <br/>
                    <ul class="oaks-list">
                        <li>
                            <p><b>100+</b> key parameters to choose from</p>
                        </li>
                        <li>
                            <p>All configurations  are <b>simulated</b> before going live</p>
                        </li>
                        <li>
                            <p><b>Constant</b> communication at all stages of the process</p>
                        </li>
                    </ul>
                </section>

                <section class="offset-section" ref="jackpots-settings-section">
                    <h6 class="offset-title full-width">JACKPOTS SETTINGS</h6>
                    <p>The following parameters can be customised to align with your preferences:</p>
                    <br/>
                    <ul class="oaks-list">
                        <li>
                            <p>List of games</p>
                        </li>
                        <li>
                            <p>Promotion duration</p>
                        </li>
                        <li>
                            <p>Jackpot type</p>
                        </li>
                        <li>
                            <p>Prize pool (depending on the Jackpot type)</p>
                        </li>
                    </ul>

                </section>

            </div>

        </div>
    </main>
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import Swiper from "@/assets/js/Swiper";


export default {
    name: "PromoToolsLuckyDrops",
    components: {
        BreadCrumbs,
    },
    props: {
        user: Object,
    },

    setup() {

    },

    mounted() {
        this.initSwiperFeatures();
    },

    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'Promo Tools',
                    href: this.$router.resolve({ name: 'client-area-promo-tools' }).path
                },

                {
                    title: 'Jackpots',
                }
            ],
        }
    },

    methods: {
        initSwiperFeatures() {
            if (this.swiperFeatures) return;
            let swiperFeatures = new Swiper(this.$refs.swiperFeatures, {
                init: false,
                observer: true,
                speed: 900,
                observeParents: true,
                slidesPerView: 5,
                watchSlidesProgress: true,
                autoHeight: true,
                grabCursor: true,
                slideActiveClass: '_active-slide',
                slideVisibleClass: '_visible-slide',
                navigation: {
                    prevEl: this.$refs.swiperFeaturesPrevSlideButton,
                    nextEl: this.$refs.swiperFeaturesNextSlideButton,
                    disabledClass: '_disabled',
                },
                pagination: {
                    el: this.$refs.swiperFeaturesProgressbar,
                    type: 'progressbar',
                },
                breakpoints: {
                    320: {
                        spaceBetween: 16,
                        slidesPerView: 2.8,
                    },
                    480: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                    },
                    640: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                    },
                    780: {
                        spaceBetween: 32,
                        slidesPerView: 4,
                    },
                    992: {
                        spaceBetween: 40,
                        slidesPerView: 4,
                    },
                    1200: {
                        spaceBetween: 40,
                        slidesPerView: 5,
                    },
                    1920: {
                        spaceBetween: 48,
                        slidesPerView: 6,
                    }
                }
            });
            swiperFeatures.init();
            this.swiperFeatures = swiperFeatures;
        },

        initSwiperSlideInfo() {
            if (this.swiperSlideInfo) return;
            let swiperSlideInfo = new Swiper(this.$refs.swiperSlideInfo, {
                init: false,
                observer: true,
                speed: 900,
                observeParents: true,
                slidesPerView: 1,
                autoWidth: false,
                autoHeight: true,
                grabCursor: true,
                slideActiveClass: '_active-slide',
                navigation: {
                    prevEl: this.$refs.swiperSlideInfoPrevSlideButton,
                    nextEl: this.$refs.swiperSlideInfoNextSlideButton,
                    disabledClass: '_disabled',
                },
                pagination: {
                    el: this.$refs.swiperSlideInfoProgressbar,
                    type: 'progressbar',
                },
            });
            swiperSlideInfo.init();
            this.swiperSlideInfo = swiperSlideInfo;
        },

        onAnchorClick(e) {
            let hash = e.target.hash.slice(1);
            this.gotoAnchor(hash);
        },

        gotoAnchor(hash) {
            let el = this.$refs[`${hash}-section`];
            if (!el) {
                return;
            }
            let rect = el.getBoundingClientRect()
            setTimeout(() => {
                window.scrollTo({
                    top: rect.top + window.pageYOffset,
                    behavior: 'smooth'
                });
            }, 50);
        },
    },
}

</script>