<template>
    <main class="page promo-tools-page">
        <div class="page__body promo-tools__page-content">

            <div class="first-screen offset-section_small first-screen_full">
                <div class="promo-tools__page-container">

                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <BreadCrumbs :links="breadCrumbsLinks" />
                    <!-- ==================================== BREADCRUMBS ========================================= -->

                    <div class="first-screen__body">
                        <div class="first-screen__left">
                            <div class="page-heading">
                                <h1>
                                    <span>LUCKY DROPS</span>
                                </h1>
                            </div>
                        </div>
                        <div class="first-screen__right"></div>
                    </div>
                </div>
            </div>

            <!-- ==================================== PAGE TITLE AND DESCRIPTION ========================================= -->
            <div class="promo-tools__page-container">
                <div class="promo-tools__page_description">
                  <div class="ptp-image">
                    <img src="@/assets/img/promo-tools/icons/lucky-drops.png" alt="3oaks" class="promo-tools__page-image">
                  </div>
                  <div class="ptp-description">
                    <div class="promo-tools__text-container">
                          <p>
                            Our Lucky Drops promo tool is designed to minimise activity gaps between campaigns,
                            supercharging engagement for the entire campaign period.
                          </p>

                          <p>
                            Every qualifying spin can trigger a random prize from a predetermined prize pool—giving
                            players an exciting reason to keep coming back to play more!
                          </p>

                          <p>
                            Lucky Drops can also work as a <strong>booster</strong> for other promo tools to
                            drive the HIGHEST possible retention
                            (easily combined with Tournaments, Jackpots and Daily Rewards).
                            You can use it for short-term or long-term campaigns, across all games or exclusively
                            for pre-releases.
                          </p>
                        </div>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE TITLE AND DESCRIPTION ========================================= -->

            <!-- ==================================== PAGE NAVIGATION ========================================= -->
            <div class="promo-tools__page-container">
                <h2 class="promo-tools__page-sub-title">
                  <strong>JUMP TO</strong>
                </h2>
                <PageNav :items="pageNavList" />
            </div>
            <!-- ==================================== PAGE NAVIGATION ========================================= -->

            <!-- ==================================== PAGE CONTENT: WHY WORTH IT ========================================= -->
            <div class="promo-tools__page-container" id="why-it-worth">
                <h2 class="promo-tools__page-sub-title">
                  <strong>WHY IT WORTH</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                  <ul class="promo-tools__oaks-decorated-list">
                    <li>
                      <strong>No need for additional</strong> setups or integrations
                    </li>
                    <li>
                      <strong>Auto-payouts</strong> with instant rewards
                    </li>
                    <li>
                      <strong>Reduces activity gaps</strong> between campaigns
                    </li>
                    <li>
                      <strong>Grows</strong> the bet amount per gaming session <strong>+ boosts</strong> the average spin number
                    </li>
                    <li>
                      Builds the habit of coming back to play daily = loyalty = bigger player base = juicy profits
                    </li>
                    <li>
                      Available across the <strong>entire 3 Oaks portfolio</strong>
                    </li>
                    <li>
                      Can run <strong>simultaneously</strong> with other promo campaigns
                    </li>
                  </ul>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: WHY WORTH IT ========================================= -->

            <!-- ==================================== PAGE CONTENT: HOW DOES IT WORKS ========================================= -->
            <div class="promo-tools__page-container" id="how-does-it-works">
                <h2 class="promo-tools__page-sub-title">
                  <strong>How does it work?</strong>
                </h2>
                <div class="promo-tools__gradient-decorated-block">
                  <ul class="promo-tools__oaks-decorated-list">
                    <li>
                      Every qualifying spin holds the potential to trigger a random prize from a set prize pool.
                    </li>
                    <li>
                      The player's adventure unfolds in three stages: <strong>JOIN</strong> - a
                      Welcome Pop-Up urges the player to join the campaign; <strong>SPIN</strong> -
                      engaging gameplay and the allure of potential prizes on-screen create excitement;
                      <strong>WIN</strong> - the player catches a Lucky Drop reward at random.
                    </li>
                    <li>
                      <strong>Hot period</strong>: an in-built feature that increases the frequency of Lucky Drops,
                      boosting player activity in the periods when engagement would have normally decreased.
                    </li>
                    <li>
                      A special in-game <strong>widget</strong> and dedicated menu provide real-time
                      details on the available prizes.
                    </li>
                  </ul>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: HOW DOES IT WORKS ========================================= -->

            <!-- ==================================== PAGE CONTENT: WORKFLOW LIST ========================================= -->
            <div class="promo-tools__page-container" id="workflow">
                <h2 class="promo-tools__page-sub-title">
                  <strong>WORKFLOW</strong>
                </h2>
              <ol class="promo-tools__workflow-list">
                <PromoWorkflowLi>
                  We finalise all Lucky Drops conditions and details
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  3 Oaks handles Lucky Drops setup and provides tech support
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  You advertise the Lucky Drops campaign & games to your audience
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  Campaign pre-notification
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Lucky Drops campaign runs in real-time
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Lucky Drops campaign has finished
                </PromoWorkflowLi>
                <PromoWorkflowLi>
                  The Lucky Drops Results Report is available for download, where you can find the
                  detailed breakdown of the prize pool, including prizes left and prizes won
                </PromoWorkflowLi>
              </ol>
            </div>
            <!-- ==================================== PAGE CONTENT: WORKFLOW LIST ========================================= -->

            <!-- ==================================== PAGE CONTENT: SCORING RULES ========================================= -->
            <div class="promo-tools__page-container" id="scoring-rules">
                <h2 class="promo-tools__page-sub-title">
                  <strong>SCORING RULES</strong>
                </h2>
                <div>
                  <div class="promo-tools__gradient-decorated-block">
                    <p>
                      Every qualifying spin can trigger a random prize from a predetermined prize pool.
                    </p>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: SCORING RULES ========================================= -->

            <!-- ==================================== PAGE CONTENT: PRIZE AUTO-PAYOUT FEATURE ========================================= -->
            <div class="promo-tools__page-container" id="prize-auto-payout-feature">
                <h2 class="promo-tools__page-sub-title">
                  <strong>PRIZE AUTO-PAYOUT FEATURE</strong>
                </h2>
                <div>
                  <div class="promo-tools__gradient-decorated-block">
                    <p>
                      The auto-payout feature ensures that instant rewards are automatically
                      paid out within the Lucky Drops campaign.
                    </p>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: PRIZE AUTO-PAYOUT FEATURE ========================================= -->

            <!-- ==================================== PAGE CONTENT: PRIZE OPTIONS ========================================= -->
            <div class="promo-tools__page-container" id="prize-options">
                <h2 class="promo-tools__page-sub-title">
                  <strong>PRIZE OPTIONS</strong>
                </h2>
                <div class="promo-tools__top-line">
                  <p>
                    The Lucky Drops campaign offers a variety of prize options:
                  </p>
                </div>
                <div class="promo-tools__grid-3">
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Money</h4>
                      <p>A prize pool is distributed based on the number and size of prizes</p>
                    </div>
                  </div>
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Prize multiplier</h4>
                      <p>
                        Prizes are granted depending on the player’s bet amount, encouraging higher
                        wagers per session
                      </p>
                    </div>
                  </div>
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Custom prizes</h4>
                      <p>
                        Operators can customise rewards to match customer preferences, provided they can
                        deliver the prize to the customer
                      </p>
                    </div>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: PRIZE OPTIONS ========================================= -->

            <!-- ==================================== PAGE CONTENT: TOURNAMENT SETTINGS ========================================= -->
            <div class="promo-tools__page-container" id="lucky-drops-settings">
                <h2 class="promo-tools__page-sub-title">
                  <strong>LUCKY DROPS SETTINGS</strong>
                </h2>
                <div class="promo-tools__grid-2">
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Local campaigns</h4>
                      <p class="promo-tools__text ptp-gray ptp-with-offset">
                        The following parameters can be customised to align with your preferences:
                      </p>
                      <ul class="promo-tools__oaks-decorated-list">
                        <li>
                          List of games
                        </li>
                        <li>
                          Promotion duration (daily, weekly, 2-day campaigns, 3-day campaigns, etc.)
                        </li>
                        <li>
                          Prize pool
                        </li>
                        <li>
                          Number of prizes
                        </li>
                        <li>
                          Individual prize sizes
                        </li>
                        <li>
                          Maximum number of prizes per player
                        </li>
                        <li>
                          Hot Period
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="promo-tools__gradient-decorated-block">
                    <div class="promo-tools__text-container">
                      <h4 class="promo-tools__small-title">Network campaigns:</h4>
                      <p class="promo-tools__text ptp-gray">
                        <strong>
                          To participate in the Lucky Drops network campaign, simply notify your Account Manager.
                          We provide all promo materials.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
            </div>
            <!-- ==================================== PAGE CONTENT: TOURNAMENT SETTINGS ========================================= -->

        </div>
    </main>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import PageNav from "./components/page_nav.vue";
import PromoWorkflowLi from "./components/promo_workflow_li.vue";


export default {
    name: "PromoToolsLuckyDrops",
    components: {
        BreadCrumbs,
        PageNav,
        PromoWorkflowLi
    },
    props: {
        user: Object,
    },
    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'Promo Tools',
                    href: this.$router.resolve({ name: 'client-area-promo-tools' }).path
                },

                {
                    title: 'Lucky Drops',
                }
            ],
            pageNavList: [
                {
                  displayText: 'Why it worth',
                  anchor: 'why-it-worth'
                },
                {
                  displayText: 'How does it works',
                  anchor: 'how-does-it-works'
                },
                {
                  displayText: 'Workflow',
                  anchor: 'workflow'
                },
                {
                  displayText: 'Scoring rules',
                  anchor: 'scoring-rules'
                },
                {
                  displayText: 'Prize auto-payout feature',
                  anchor: 'prize-auto-payout-feature'
                },
                {
                  displayText: 'Prize option',
                  anchor: 'prize-options'
                },
                {
                  displayText: 'Lucky Drops settings',
                  anchor: 'lucky-drops-settings'
                },
            ],
        }
    },
}

</script>