<template>
    <!-- =================================== PROMO CARD ===================================  -->
    <div class="promo-tools__card"
        :class="{'no-hover': isMobile, 'hover': flipped, 'prevent-selection': flipping}"
        @click="onCardClickHandle"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
        @touchmove="onTouchMove">
        <div class="pts-flipper">
            <div class="pts-icon">
              <slot name="image"></slot>
              <h4>
                <slot name="title"></slot>
              </h4>
            </div>
            <div class="pts-description">
              <slot name="description"></slot>
            </div>
        </div>
    </div>
    <!-- =================================== PROMO CARD ===================================  -->
</template>

<script>
import {isMobile} from '@/lib/browser';

export default {
    name: "PromoCard",
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isMobile: false,
            flipped: false,
            flipping: false,
            touchTimeout: null,
            touchHoldActive: false,
            isScrolling: false,
        };
    },
    methods: {
        onCardClickHandle(event) {
            if (this.isMobile) return;

            if (event) event.preventDefault();
            window.location.href = this.$router.resolve({ name: this.url }).href;
        },
        onTouchStart(event) {
            this.flipping = true;
            this.touchHoldActive = false;
            this.isScrolling = false;

            this.startTouchY = event.touches[0].clientY;

            this.touchTimeout = setTimeout(() => {
                this.flipped = true;
                this.flipping = false;
                this.touchHoldActive = true;
            }, 500);
        },
        onTouchEnd(event) {
            if (this.touchTimeout) {
                clearTimeout(this.touchTimeout);
                this.touchTimeout = null;
            }
            this.flipping = false;

            if (this.touchHoldActive || this.isScrolling) return;

            window.location.href = this.$router.resolve({ name: this.url }).href;
        },
        onTouchMove(event) {
            const touchMove = Math.abs(event.touches[0].clientY - this.startTouchY);

            if (touchMove > 5) {
                this.isScrolling = true;
                clearTimeout(this.touchTimeout);
            }
        },
        resetFlippedState() {
            this.isScrolling = false;
            this.flipped = false;
            this.flipping = false;
        },
        handlePageShow() {
          this.resetFlippedState();
        },
    },
    mounted() {
        this.isMobile = isMobile.any()
        window.addEventListener("pageshow", this.handlePageShow);
    },
    beforeDestroy() {
        window.removeEventListener("pageshow", this.handlePageShow);
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
          vm.resetFlippedState();
        });
    },
};
</script>