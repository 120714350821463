<template>
    <main class="page promo-tools">
        <div class="page__body promo-tools__body">

            <div class="first-screen offset-section_small first-screen_left">
                <div class="_container">
                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <BreadCrumbs :links="breadCrumbsLinks" />
                    <!-- ==================================== BREADCRUMBS ========================================= -->
                    <div class="first-screen__body">
                        <div class="first-screen__left">
                            <div class="page-heading page-heading_colors">
                                <h1><span>PROMO <em>TOOLS</em></span></h1>
                            </div>

                            <div class="page-description _icon-arrow-2 page-description_offset">
                                <p>
                                    Players love our slots! Our games are crafted with a precise attention to every
                                    detail of the gameplay experience, maximizing players’ retention and casino profit.
                                </p>
                            </div>
                        </div>
                        <div class="first-screen__right"></div>
                    </div>
                </div>
            </div>

            <!-- =================================== NEWS SECTION ===================================  -->
            <section class="offset-section">
                <div class="_container">
                  <div class="promo-tools__list">
                    <PromoCard url="client-area-promo-tools-tournaments">
                      <template #image>
                        <img :src="images.tournamentIcon" alt="3oaks">
                      </template>
                      <template #title>
                        <strong>TOURNAMENTS</strong>
                      </template>
                      <template #description>
                        <p>
                          Our Tournaments promo tool sparks the <strong>competitive spirit</strong> in players,
                          ensuring they are deeply involved in the game, resulting in a massive boost in
                          engagement and retention rates.
                        </p>
                      </template>
                    </PromoCard>
                    <PromoCard url="client-area-promo-tools-tournaments-series">
                      <template #image>
                        <img :src="images.tournamentSeriesIcon" alt="3oaks">
                      </template>
                      <template #title>
                        <strong>TOURNAMENTS SERIES</strong>
                      </template>
                      <template #description>
                        <p class="promo-tools__smaller_description">
                          Our Tournament Series tool is designed to keep players engaged over extended periods
                            by offering a dynamic experience through a series of tournaments, with chances for
                            extra rewards based on active involvement.
                        </p>
                      </template>
                    </PromoCard>
                    <PromoCard url="client-area-promo-tools-lucky-drops">
                      <template #image>
                        <img :src="images.luckydropsIcon" alt="3oaks">
                      </template>
                      <template #title>
                        <strong>LUCKY DROPS</strong>
                      </template>
                      <template #description>
                        <p>
                          Our Lucky Drops tool minimises activity gaps between campaigns by offering
                          players exciting random prizes. It also pairs well with other tools for supercharged
                          player retention.
                        </p>
                      </template>
                    </PromoCard>
                    <PromoCard url="client-area-promo-tools-flip-to-win">
                      <template #image>
                        <img :src="images.fliptowinIcon" alt="3oaks">
                      </template>
                      <template #title>
                        <strong>FLIP TO WIN</strong>
                      </template>
                      <template #description>
                        <p>
                          Our Flip to Win promo tool is a unique solution to drive continuous play,
                          where each FLIP guarantees a win. An experience that players simply can't resist.
                        </p>
                      </template>
                    </PromoCard>
                  </div>
                </div>
            </section>
            <!-- =================================== NEWS SECTION ===================================  -->

        </div>
    </main>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import PromoCard from './components/promo_card.vue';

import tournamentIcon from '@/assets/img/promo-tools/icons/tournaments.png';
import tournamentSeriesIcon from '@/assets/img/promo-tools/icons/tournament-series.png';
import luckydropsIcon from '@/assets/img/promo-tools/icons/lucky-drops.png';
import fliptowinIcon from '@/assets/img/promo-tools/icons/flip-to-win.png';

export default {
    name: "PromoToolsList",
    components: {
        BreadCrumbs,
        PromoCard
    },
    props: {
        user: Object,
    },
    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'Promo Tools',
                    href: this.$router.resolve({ name: 'client-area-promo-tools' }).path
                }
            ],
            images: {
              tournamentIcon,
              luckydropsIcon,
              fliptowinIcon,
              tournamentSeriesIcon
            }
        }
    },
}

</script>