<template>
  <div class="promo-tools__expanded">
    <div class="pts-trigger" @click="onTriggerClick">
      <img src="@/assets/img/promo-tools/icons/arrow.svg" :class="{'expanded': expanded}">
      <slot name="trigger"></slot>
    </div>
    <div v-if="expanded" class="pts-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoExpanded',
  props: {
    defaultExpanded: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      expanded: this.defaultExpanded || false
    };
  },
  methods: {
    onTriggerClick() {
      this.expanded = !this.expanded;
    }
  }
};
</script>