<template>
  <ul class="promo-tools__page-nav">
    <li v-for="item in items">
      <span class="pts-item" @click="onItemClickHandle(item.anchor)">{{ item.displayText }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PageNav',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    onItemClickHandle(anchor) {
      const scrollToSection = document.getElementById(anchor)
      if (!scrollToSection) {
        return
      }
      setTimeout(() => {
        scrollToSection.scrollIntoView({
          behavior: 'smooth'
        })
      }, 50)
    }
  }
}
</script>