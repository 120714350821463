<template>
  <StaticPage title="Privacy Notice">
    <h3>THE PURPOSE OF THIS PRIVACY NOTICE</h3>

    <p>
      The purpose of this privacy notice is to explain how Green Rock Limited (“<strong>Green Rock</strong>” or
      “<strong>the Company</strong>” or “<strong>we</strong>” or “<strong>our</strong>”) collect, use, and process your personal information through your
      use of our website. This privacy notice applies to all visitors to our website, as we may collect
      information to provide you with a service or a better user experience.
    </p>

    <p>
      We will ensure that the personal information provided by you is collected, protected and processed
      carefully and responsibly and in line with applicable laws including but not limited to the Data
      Protection act 2018 and Regulation (EU) 2016/679) of the European Parliament and the Council of
      27 April 2016 on the protection of natural persons with regard to the processing of personal data
      and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection
      Regulation) – the “GDPR”. This privacy notice supplements other notices and privacy policies and is
      not intended to override them.
    </p>

    <p>&nbsp;</p>

    <h3>INFORMATION WE COLLECT ABOUT YOU</h3>

    <p>
      Personal information means any information about a natural or legal person from which that person
      can be identified. It does not include information where the identity has been removed (anonymous
      information). Below is an overview of the information we may collect when you interact with us
      through our website:
    </p>

    <table border="0" cellpadding="1" cellspacing="1" style="width: 100%">
      <tbody>
        <tr>
          <td>Contact information:</td>
          <td>Name, email address, telephone number, company namer</td>
        </tr>
        <tr>
          <td>Other information:</td>
          <td>Feedback, comments, complaints</td>
        </tr>
        <tr>
          <td>Tracking information:</td>
          <td>Read more in Section 5 below.</td>
        </tr>
      </tbody>
    </table>

    <p>&nbsp;</p>

    <p>
      We use the information you provide to deal with the subject matter. We process your personal
      information because it is in our legitimate interest to do so.
    </p>

    <p>&nbsp;</p>

    <h3>COOKIES</h3>

    <p>
      Cookies are small text files stored in your browser that we use to recognize your computer when
      you access our website. Our website makes use of data to enhance functionality. You may, at any time,
      refuse to accept cookies by changing their browser settings, or delete cookies that are already stored
      on your computer.
    </p>

    <p>
      We will inform you about the use of cookies by displaying a "cookie banner" when you first access
      our website. If you continue to use our website without changing your browser settings, we will use
      cookies according to their descriptions below:
    </p>

    <p>
      <strong>Necessary cookies</strong>: These are essential to the operation of our websites.
    </p>

    <table border="0" cellpadding="1" cellspacing="1" style="width: 100%">
      <thead>
        <tr>
          <th scope="col">Cookie</th>
          <th scope="col">Expiry</th>
          <th scope="col">Purpose</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="text-align: center">site-3oaks-session</td>
          <td style="text-align: center">one week</td>
          <td style="text-align: center">to login to client area</td>
        </tr>
        <tr>
          <td>
            <p style="text-align: center">site-3oaks-session-tournament</p>
          </td>
          <td style="text-align: center">one week</td>
          <td style="text-align: center">to participate in tournaments</td>
        </tr>
      </tbody>
    </table>

    <p>&nbsp;</p>

    <h3>PRINCIPLES OF DATA PROCESSING</h3>

    <p>
      We fully respect your fundamental rights and consider protection of your personal data to be a
      priority. Accordingly, when processing your personal data, we follow the following basic principles:
    </p>

    <ul>
      <li>
        We submit your personal data only for lawful and fair processing, and we maintain full
        transparency vis-à-vis the way We handle your personal data;
      </li>
      <li>
        We collect and process your personal data only for specified, explicit, and legitimate purposes
        as outlined in this Privacy Policy, and We do not process it further in any manner incompatible
        with these purposes;
      </li>
      <li>
        We process your personal data only to the extent that it is necessary and appropriate to
        purposes for which it is collected;
      </li>
      <li>
        We make reasonable efforts to ensure that your personal data is accurate and, where
        necessary, updated with regard to the purposes of the processing, taking all reasonable steps
        to immediately delete or correct it in case of inaccuracy; and
      </li>
      <li>
        We process your personal data in a manner that guarantees its security by using appropriate
        technical and organisational measures.
      </li>
    </ul>

    <p>
      In general, we comply with all applicable laws and statutory obligations, as Data Controller of your
      personal data.
    </p>

    <p>&nbsp;</p>

    <h3>PURPOSE AND LEGAL BASIS FOR DATA PROCESSING</h3>

    <p>
      The Company may process Your Personal Data to fulfil its obligations under the Terms and
      Conditions, to comply with its legal obligations and for the following purposes:
    </p>

    <ul>
      <li>
        Administration and development of the Website;
      </li>
      <li>
        Enhancement of User’s experience;
      </li>
      <li>
        Detection, investigation and prevention of fraudulent activity and other illegal activities and
        protection of Your rights and rights of the Company;
      </li>
      <li>
        Collection, processing and performing statistical and other research and analysis of
        information for enhancement of the Website and the Services;
      </li>
      <li>
        Verifying compliance with the Terms and Conditions of the Website.
      </li>
    </ul>

    <p>
      The Company may request and process additional Personal Data only with Your lawful consent for
      the following purposes:
    </p>

    <ul>
      <li>
        For the purposes of commercial communication, marketing and advertising of Our Services
        or third-party Services via SMS, telephone, e-mail, internet, fax, mail, social media and/or any
        other appropriate communication channels.
      </li>
      <li>
        For personified market research and/or analysis purposes to better understand Your needs,
        preferences, interests, experiences and / or habits as a consumer.
      </li>
    </ul>

    <p>
      You have the right to withdraw Your consent to Our processing of Your Personal Data at any time
      in writing to Our contact details mentioned in this Policy. Withdrawal of Your consent does not
      affect the lawfulness of the treatment of Your Personal Data prior to its revocation.
    </p>

    <p>&nbsp;</p>

    <h3>CHILDREN'S INFORMATION</h3>

    <p>
      Our website is only intended for adults over the age of 18. We do not knowingly collect the personal
      information of persons under 18 years of age.
    </p>

    <p>&nbsp;</p>

    <h3>WHO WE SHARE YOUR INFORMATION WITH</h3>

    <p>
      There may be instances where your information is shared with service providers. We only engage
      with service providers that we trust, who have agreed to keep your personal information secure and
      confidential and to only use it for the purpose for which we shared it with them.
    </p>

    <p>
      We use service providers to assist with legitimate business purposes such as storing information,
      managing our business, providing IT and infrastructure services. Some of the service providers that we
      use may be located in other countries. These countries may not have the same levels of protection of
      personal information as the Isle of Man, in which case the foreign service providers must undertake
      to protect the personal information of our customers to the same level that we do. We provide for
      appropriate safeguards by means of contracts between us and our foreign service providers. By using
      our website, you agree to not being notified of details regarding the level of protection afforded to
      your information in the relevant country/countries.
    </p>

    <p>
      We will not sell or share your personal information to companies, organisations or individuals. We
      may be required to share your personal information with regulatory and law enforcement bodies.
    </p>

    <p>&nbsp;</p>

    <h3>HOW WE PROTECT YOUR PERSONAL INFORMATION</h3>

    <p>
      We take reasonable steps to safeguard your personal information against loss, interference,
      unauthorised access, use, modification, disclosure or other misuse. We have implemented appropriate
      technical, physical and organizational measures to protect your personal information. We continuously
      review our security controls and processes to ensure that security risks are identified and addressed.
    </p>

    <p>&nbsp;</p>

    <h3>HOW LONG WE KEEP PERSONAL INFORMATION</h3>

    <p>
      We will only keep your information for the minimum time necessary. This may be to respond to an
      enquiry from you and/or provide you with a service that you requested.
    </p>

    <p>
      We will not retain your information unless we are legally required to do so. When we no longer need
      your personal information, we will securely delete or destroy it in line with the requirements of the
      data protection law.
    </p>

    <p>&nbsp;</p>

    <h3>CHANGES TO THIS PRIVACY NOTICE</h3>

    <p>
      We keep our privacy notice under regular review, and therefore encourage you to check our privacy
      notice regularly so that you remain up to date on how we process and protect your personal
      information.
    </p>

    <h3>YOUR DUTY TO INFORM US OF CHANGES</h3>

    <p>
      It is important that the personal information we hold about you is accurate and current. Please keep
      us informed if your personal information changes during your relationship with us.
    </p>

    <h3>WHAT ARE YOUR RIGHTS?</h3>

    <p>
      You have several rights towards your personal information. These include the right to:
    </p>

    <ul>
      <li>
        To ask to see that information we hold about you by submitting a Subject Access Request.
      </li>
      <li>
        To review your personal information and ensure it is accurate.
      </li>
      <li>
        To remove, rectify, erase or object to your personal information being processed.
      </li>
      <li>
        To withdraw your consent at any time where we are relying on consent to process your
        personal information.
      </li>
      <li>
        To make a complaint to the Information Commissioner regarding an alleged interference with
        the protection of your personal information.
      </li>
    </ul>

    <p>
      You may request access to the personal information that we hold about you or correct your personal
      information. It can take us up to 21 days to respond to your request due to procedures that we are
      required to follow. In certain cases, we may require proof of your identity and sometimes changes to
      your personal information may be subject to additional requirements such as valid proof of residence.
      Please be informed that certain rights may not be fulfilled entirely due to legal or regulatory
      requirements.
    </p>

    <h3>CONTACT INFORMATION</h3>

    <p>
      If you have questions or concerns regarding the processing of your personal information, please
      contact us at:
    </p>

    <p>
      Green Rock Ltd: Compliance Department
    </p>

    <p>
      Email: compliance@3oaks.com
    </p>

    <p>
      Postal address: PO Box 227 Peveril Buildings, Peveril Square Douglas, Isle of Man IM99 1RZ
    </p>
    <br />
    <p>
        <small>Version 2</small>
    </p>
  </StaticPage>
</template>

<script>
import StaticPage from "@/components/static_page";

export default {
  name: "PrivacyNotice",
  components: {
    StaticPage,
  },
};
</script>
