<template>
  <li>
    <div class="ptp-list-item-content"><slot></slot></div>
    <div class="ptp-list-item-dash-decor"></div>
  </li>
</template>

<script>
export default {
  name: 'PromoWorkflowLi'
}
</script>