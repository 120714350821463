<template>
  <div class="promo-tools__slider _swiper" ref="mySwiper">
    <div class="pts-wrapper swiper-wrapper">
      <div
        class="pts-slide swiper-slide"
        v-for="(slide, index) in slides"
        :key="index"
      >
        <slot :name="'slide-' + index"></slot>
      </div>
    </div>
    <div class="pts-footer">
      <div class="pts-progress-bar"></div>
      <div class="pts-controls">
        <span class="pts-control pts-swiper-prev">
          <img src="@/assets/img/promo-tools/slider-control.svg" class="reversed">
        </span>
        <span class="pts-control pts-swiper-next">
          <img src="@/assets/img/promo-tools/slider-control.svg">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from '@/assets/js/Swiper';

export default {
  name: "PromoSlider",
  data() {
    return {
      progressWidth: 0,
      swiperInstance: null,
      isPrevDisabled: true,
      isNextDisabled: false,
    };
  },
  computed: {
    slides() {
      return Object.keys(this.$slots)
          .filter((slotName) => slotName.startsWith("slide-"));
    }
  },
  mounted() {
    this.swiperInstance = new Swiper(this.$refs.mySwiper, {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".pts-progress-bar",
        type: "progressbar",
      },
      navigation: {
        nextEl: ".pts-swiper-next",
        prevEl: ".pts-swiper-prev",
      },
    });
  },
};
</script>